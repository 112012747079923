<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :title="title" back="Convention.Badges.Holders">
		
		<template v-if="$authorised('con/badges/access', 'write')">

		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="check" text="Confirm" v-on:click="onSaveClick" />

		</template>

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-box title="Transfer from" icon="transfer.from" :readonly="$authorised('con/badges/access', 'read')">

			<app-input-suggest label="Badge" placeholder="Search badges..." v-model="model.badge" api="convention/badge" :validation="$v.model.badge" />
			
		</app-content-box>

		<app-content-box title="Transfer to" icon="transfer.to" :readonly="$authorised('con/badges/access', 'read')">

			<app-input-account :original="''" label="E-mail" endpoint="convention/badges/transfer/exists" :reverse="true" type="badge" :text="emailMessages" v-model="model.email" :validation="$v.model.email" v-on:checking="is.checking = $event" v-on:exists="onExistsChange" />
			<app-input-text label="First name" v-model="model.firstname" :validation="$v.model.firstname" placeholder="Enter first name" :maxlength="32" />
			<app-input-text label="Last name" v-model="model.lastname" :validation="$v.model.lastname" placeholder="Enter last name" :maxlength="32" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, maxLength, email } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/badges/access', 'read'),
			model: {
				id: false,
				firstname: '',
				lastname: '',
				email: '',
				badge: false
			},
			emailMessages: {
				info: 'Please note the e-mail address cannot already be associated with an existing badge.',
				exists: 'This e-mail address is already associated with another badge.',
				available: 'This e-mail is available to transfer to.'
			},
			exists: false
		}

	},

	validations: {
		model: {
			badge: {
				required
			},
			firstname: {
				required,
				maxLength: maxLength(32)
			},
			lastname: {
				required,
				maxLength: maxLength(32)
			},
			email: {
				email,
				notExist: function() {

					return !this.exists

				}
			}
		}
	},

	methods: {

		onSaved: function() {

			this.$router.push({
				name: 'Convention.Badges.Transfers'
			})

		},

		onExistsChange: function(e) {

			this.exists = e.exists

		}

	}

}

</script>

<style scoped>

</style>